.content {
    --gutter-size: 0.5rem;
    --picture-size: 25rem;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat( auto-fit, minmax(16rem, 25rem));
    grid-gap: var(--gutter-size);
    align-items: top;
    align-content: center;
    justify-items: center;
    justify-content: center;
    padding: var(--gutter-size);
}

.title {
    grid-column: 1 / -1;
    text-align: center;
    align-content: center;
    vertical-align: middle;
}

.subtitle {
    grid-column: 1 / -1;
    text-align: left;
    align-content: left;
    vertical-align: middle;
}

.headline {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.media {
    max-width: var(--picture-size);
}

.grid-break {
    grid-column: 1 / -1;
}

.button-content {
    width: 100%;
}

.card-divider {
    box-sizing: border-box;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.paragraph-mount {
    grid-column: 1 / -1;
    /*width: calc(100vw - calc(2 * var(--gutter-size)));*/
}

.paragraph {
    --portrait-size: 16rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(var(--portrait-size), var(--portrait-size)));
    grid-gap: calc(2 * var(--gutter-size));
    align-items: top;
    align-content: center;
    justify-items: center;
    justify-content: center;
}

.selfie {
    order: 1;
    max-width: var(--portrait-size);
    grid-column: 1 / span 1;
}

.bio {
    order: 2;
}

/* 50.5 rem = 2 * var(--picture-size) + 3 * var(--gutter-size) */

@media (min-width: 51.5rem) {
    .bio {
        grid-column: 2 / -1;
    }
}

@media (max-width: 51.5rem) {
    .bio {
        grid-column: 1 / -1;
    }
}